.dis-none {
  display: none !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c3054 !important;
  border-radius: 1000px !important;
  border: 2px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #042c75 !important;
}

.navbar-wrapper {
  padding-left: 104px !important;
  padding-right: 104px !important;
}

.menu-icon {
  min-width: 40px !important;
}

.calculator-wrapper,
.static-section-wrapper,
.dilution-wrapper,
.coc-wrapper {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.calculator-wrapper {
  padding-top: 80px !important;
  padding-bottom: 120px !important;
}

/* .static-access-h6 {
  white-space: nowrap !important;
} */

.net-amount {
  margin-top: 0 !important;
}

.line-1 {
  position: absolute;
  width: 239.47px;
  height: 236.47px;
  left: -118.33px;
  top: 110px;
  border-radius: 20px;
  -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
          transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  z-index: 999;
}

.line-2 {
  position: absolute;
  width: 269.41px;
  height: 255.41px;
  left: -135.5px;
  top: 100px;
  border-radius: 20px;
  -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
          transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  z-index: 999;
}

.line-3 {
  position: absolute;
  width: 299.34px;
  height: 299.34px;
  left: -144.66px;
  top: 70px;
  border-radius: 20px;
  -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
          transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  z-index: 999;
}

.line-4 {
  position: absolute;
  width: 329.28px;
  height: 329.28px;
  left: -157px;
  top: 50px;
  border-radius: 20px;
  -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
          transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  z-index: 999;
}

.line-5 {
  position: absolute;
  width: 359.21px;
  height: 359.21px;
  top: 30px;
  left: -170px;
  border-radius: 20px;
  z-index: 999;
  -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
          transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
}

.line-1::before,
.line-2::before,
.line-3::before,
.line-4::before,
.line-5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  border: 5px solid transparent;
  border-bottom: transparent;
  border-left: transparent;
  background: linear-gradient(132deg, #3465ee, #3863ff00 100%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.MuiTooltip-tooltip {
  background-color: #233047 !important;
  color: white !important;
  padding: 10px 20px !important;
  font-size: 14px;
  font-weight: 500;
}

.MuiTooltip-tooltip span::before {
  background-color: #233047 !important;
  color: #233047 !important;
}

.footer-top {
  padding: 0 !important;
  margin-left: 0 !important ;
}

.footer-wrapper {
  padding: 72px 120px !important;
}

.calculater-img-wrapper {
  padding: 0 20px !important;
  height: 100% !important;
}

.calculator-field-text {
  margin-left: 0 !important;
  margin-bottom: 32px !important;
}

.save-up-to-message {
  display: flex !important;
  white-space: nowrap !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  align-items: center !important;
  font-size: 32px !important;
}

.footer-top {
  width: 100% !important;
}

.calculate-button .MuiButton-root:disabled {
  color: rgb(255 255 255 / 54%);
  box-shadow: none;
  background: linear-gradient(90deg, #62bdff 0%, #3864ff 98.91%);
  opacity: 0.5;
}
.five-line-img {
  position: absolute;
  z-index: 99;
  top: -20px;
}

@media screen and (max-width: 1024px) {
  .five-line-img {
    position: absolute;
    z-index: 99;
    top: -20px;
    height: 30%;
  }
  .line-1::before,
  .line-2::before,
  .line-3::before,
  .line-4::before,
  .line-5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border: 2px solid transparent;
    border-bottom: transparent;
    border-left: transparent;
    background: linear-gradient(132deg, #3465ee, #3863ff00 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .line-5 {
    position: absolute;
    width: 359.21px;
    height: 359.21px;
    top: 88px;
    left: -282px;
    border-radius: 20px;
    z-index: 999;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  }
  .line-4 {
    position: absolute;
    width: 329.28px;
    height: 329.28px;
    left: -271px;
    top: 115px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-3 {
    position: absolute;
    width: 299.34px;
    height: 299.34px;
    left: -254.66px;
    top: 137px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-2 {
    position: absolute;
    width: 269.41px;
    height: 255.41px;
    left: -246.5px;
    top: 173px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-1 {
    position: absolute;
    width: 239.47px;
    height: 236.47px;
    left: -230.33px;
    top: 189px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .navbar-wrapper {
    padding: 0 20px !important;
  }
  .footer-wrapper {
    padding: 20px !important;
  }
  .calculator-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .static-section-left {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    text-align: center !important;
  }
  .static-section-wrapper {
    padding: 20px !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .calculater-fields-wrapper {
    padding: 20px 16px !important;
  }
  .dilution-wrapper,
  .coc-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .dilution-left-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .coc-left {
    padding: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .line-1::before,
  .line-2::before,
  .line-3::before,
  .line-4::before,
  .line-5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border: 2px solid transparent;
    border-bottom: transparent;
    border-left: transparent;
    background: linear-gradient(132deg, #3465ee, #3863ff00 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .line-5 {
    position: absolute;
    width: 359.21px;
    height: 359.21px;
    top: 88px;
    left: -282px;
    border-radius: 20px;
    z-index: 999;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  }
  .line-4 {
    position: absolute;
    width: 329.28px;
    height: 329.28px;
    left: -271px;
    top: 115px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-3 {
    position: absolute;
    width: 299.34px;
    height: 299.34px;
    left: -254.66px;
    top: 137px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-2 {
    position: absolute;
    width: 269.41px;
    height: 255.41px;
    left: -246.5px;
    top: 173px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-1 {
    position: absolute;
    width: 239.47px;
    height: 236.47px;
    left: -230.33px;
    top: 189px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .navbar-wrapper {
    padding: 0 20px !important;
  }
  .footer-wrapper {
    padding: 20px !important;
  }
  .calculator-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .static-section-left {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .static-section-wrapper {
    padding: 20px !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .calculater-fields-wrapper {
    padding: 20px 16px !important;
  }
  .dilution-wrapper,
  .coc-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .dilution-left-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .coc-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .coc-left .MuiTypography-h6 {
    text-align: center !important;
  }
  .calculater-img-wrapper {
    border: 1px solid #233047;
    background-color: #03091480;
    border-radius: 8px;
    margin-top: 40px !important;
  }
  .calculator-image {
    border: none !important;
  }
  .calculate-button {
    width: 100%;
  }
  .calculater-fields-wrapper {
    background-color: #03091480;
    border-radius: 8px;
  }
  .dilution-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .dilution-left .MuiTypography-h6 {
    text-align: center !important;
    margin-top: 0 !important;
  }
  .save-up-to-message {
    margin-top: 27px !important;
    justify-content: center !important;
  }
  .raise-value {
    margin-top: 27px !important;
  }
  .bar-chart {
    padding: 20px 0 0 0 !important;
  }
}
@media screen and (max-width: 425px) {
  .raise-value {
    font-size: 18px !important;
  }
  .dilution-left .MuiButton-root {
    width: auto !important;
  }
  .bar-chart {
    padding: 0 !important;
  }
  .coc-left .MuiTypography-h6 {
    margin-bottom: 24px !important;
    margin-top: 0 !important  ;
  }
  .static-access-h6 {
    font-size: 18px !important;
  }
  .calculator-field-text {
    white-space: nowrap;
    font-size: 16px !important;
  }
  .raise-value h3 {
    white-space: nowrap !important;
  }
  .percent-of-company span {
    font-size: 23px !important;
  }
  .navbar-container {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .footer-wrapper {
    padding: 0 4px !important;
  }
}
@media screen and (max-width: 820px) {
  .line-1::before,
  .line-2::before,
  .line-3::before,
  .line-4::before,
  .line-5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border: 2px solid transparent;
    border-bottom: transparent;
    border-left: transparent;
    background: linear-gradient(132deg, #3465ee, #3863ff00 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .line-5 {
    position: absolute;
    width: 359.21px;
    height: 359.21px;
    top: 88px;
    left: -282px;
    border-radius: 20px;
    z-index: 999;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  }
  .line-4 {
    position: absolute;
    width: 329.28px;
    height: 329.28px;
    left: -271px;
    top: 115px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-3 {
    position: absolute;
    width: 299.34px;
    height: 299.34px;
    left: -254.66px;
    top: 137px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-2 {
    position: absolute;
    width: 269.41px;
    height: 255.41px;
    left: -246.5px;
    top: 173px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .line-1 {
    position: absolute;
    width: 239.47px;
    height: 236.47px;
    left: -230.33px;
    top: 189px;
    border-radius: 20px;
    -webkit-transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
            transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    z-index: 999;
  }
  .navbar-wrapper {
    padding: 0 20px !important;
  }
  /* .footer-wrapper {
    padding: 0 20px !important;
  } */
  .calculator-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .static-section-left {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .static-section-wrapper {
    padding: 20px !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .calculater-fields-wrapper {
    padding: 20px 16px !important;
  }
  .dilution-wrapper,
  .coc-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .dilution-left-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .coc-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .coc-left .MuiTypography-h6 {
    text-align: center !important;
    margin-top: 0 !important;
    /* width: 80% !important; */
  }
  .calculater-img-wrapper {
    border: 1px solid #233047;
    background-color: #03091480;
    border-radius: 8px;
    margin-top: 40px !important;
  }
  .calculator-image {
    border: none !important;
  }
  .calculate-button {
    width: 100%;
  }
  .calculater-fields-wrapper {
    background-color: #03091480;
    border-radius: 8px;
  }
  .dilution-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .dilution-left .MuiTypography-h6 {
    text-align: center !important;
    margin-top: 0 !important;
  }
  .save-up-to-message {
    margin-top: 27px !important;
    justify-content: center !important;
    font-size: 18px !important;
  }
  .raise-value {
    margin-top: 27px !important;
  }
  .bar-chart {
    padding: 20px 0 0 0 !important;
  }
}
@media screen and (max-width: 912px) {
  .get-capital-button {
    width: auto;
  }
  .footer-top {
    margin: 0 !important;
    width: 100% !important;
  }
  .navbar-wrapper {
    padding: 0 20px !important;
  }
  /* .footer-wrapper {
    padding: 0 20px !important;
  } */
  .calculator-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .static-section-left {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .static-section-wrapper {
    padding: 20px !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .calculater-fields-wrapper {
    padding: 20px 16px !important;
  }
  .dilution-wrapper,
  .coc-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .dilution-left-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .coc-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .coc-left .MuiTypography-h6 {
    text-align: center !important;
    margin-top: 0 !important;
    /* width: 80% !important; */
  }
  .calculater-img-wrapper {
    border: 1px solid #233047;
    background-color: #03091480;
    border-radius: 8px;
    margin-top: 40px !important;
  }
  .calculator-image {
    border: none !important;
  }
  .calculate-button {
    width: 100%;
  }
  .calculater-fields-wrapper {
    background-color: #03091480;
    border-radius: 8px;
  }
  .dilution-left {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .dilution-left .MuiTypography-h6 {
    text-align: center !important;
    margin-top: 0 !important;
  }
  .save-up-to-message {
    margin-top: 27px !important;
    justify-content: center !important;
    font-size: 18px !important;
  }
  .raise-value {
    margin-top: 27px !important;
  }
  .bar-chart {
    padding: 20px 0 0 0 !important;
  }
}

